import { Component } from '@zento-lib/components';
import { TopNavigationData } from 'theme/stores/navigation/types';

import { MenuList } from '../../List';
import { LanguageSwitcher } from '../../../LanguageSwitcher/LanguageSwitcher';
import { MenuLink } from '../../../../atom/Menu/Link/Link';
import { MenuLinkPromoted } from '../../../../atom/Menu/Promoted/Promoted';
import { MenuButton } from '../../../../atom/Menu/Button/Button';
import type { CategoryTypes } from '../../../../atom/types/CategoryTypes';

import style from './style.scss';

/**
 * Menu List
 *
 * Main menu list renderer component
 **/
@Component({})
export class MenuList2 extends MenuList {
  beforeMount() {
    this.showOverlay = this.showOverlay.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
  }

  render() {
    const level = this.depth + 2;

    return (
      <ul
        class={{ [style.navWrapper]: true, [style.masonry]: this.isDesktop && this.depth === 1 }}
        data-testId='navWrapper'>
        {(this.items as TopNavigationData[]).map((item, index) => {
          if (this.conditioning.categories[item.kind]) {
            // Category and group cases
            const hasChildren = item.children_data.length > 0;
            const isGroup = item.kind === 'group';

            return (
              <li
                onMouseEnter={this.isDesktop && hasChildren && level === 2 ? this.showOverlay : this.noOp}
                onMouseLeave={this.isDesktop && hasChildren && level === 2 ? this.hideOverlay : this.noOp}
                data-depth={this.depth}
                data-parentId={item.parent_id}
                class={{
                  [style.menuItem]: true,
                  [style[`menuItemLevel-${level}`]]: level > 2,
                }}
                key={item.id + '_' + index}>
                {hasChildren ? (
                  [
                    <MenuLink
                      to={item}
                      target={isGroup}
                      handler={(ev: Event) => this.nextSelection(ev, item)}
                      hasImage={isGroup ? false : this.isMobile && level === 3}
                      class={{ [style.menuItemLink]: level === 2 }}
                      key={'parent_link_' + item.id + '_' + index}
                    />,

                    <MenuList
                      items={item.children_data}
                      depth={this.depth + 1}
                      promotedCategories={this.isPromoted(item, level) ? [item] : []}
                      promotedItemsLimit={this.promotedItemsLimit}
                      key={'submenu_' + item.id + '_' + index}
                    />,
                  ]
                ) : (
                  <MenuLink
                    to={item}
                    handler={this.closeMenu}
                    hasImage={this.isMobile && level === 3}
                    class={style.menuItemLeaf}
                    data-leaf={level === 2}
                    key={'leaf_link_' + item.id}
                  />
                )}
              </li>
            );
          } else if (this.conditioning.external[item.kind]) {
            const key = item.to.path;

            // Link case
            return (
              <li
                data-depth={this.depth}
                class={{
                  [style.menuItem]: true,
                  [style[`menuItemLevel-${level}`]]: level > 2,
                }}
                data-parentId={item.parent_id}
                key={key + '_' + index}>
                <MenuLink
                  to={item.to}
                  emphasis={item.emphasis}
                  handler={this.closeMenu}
                  type={item.kind}
                  class={style.menuItemLeaf}
                  data-leaf={level === 2}
                  key={'link_' + key + '_' + index}
                />
              </li>
            );
          } else if (item.kind === 'account') {
            // Button case
            return (
              <li
                data-depth={this.depth}
                class={{
                  [style.menuItem]: true,
                  [style[`menuItemLevel-${level}`]]: level > 2,
                }}
                data-parentId={item.parent_id}
                key={item.id + '_' + index}>
                <MenuButton
                  id={item.id}
                  emphasis={item.emphasis}
                  type={item.type}
                  url={item.url}
                  name={item.name}
                  data-leaf={level === 2}
                  key={'button_' + item.id + '_' + index}
                />
              </li>
            );
          }

          // JSX elements
          console.debug('Unhandled list item', item);

          return item;
        })}

        {/* Language Switcher case */}
        {this.extended.$config.storeViews.multistore && !this.isDesktop && this.depth === 0 ? (
          <li data-depth={this.depth} key='language-switcher-link'>
            <LanguageSwitcher />
          </li>
        ) : null}

        {/* Promoted case */}
        {this.promotedItems.length && this.isDesktop ? (
          <li data-depth={this.depth} class={style.menuItemsPromotedWrapper} key='promoted-links-wrapper'>
            {this.promotedItems.map((item: CategoryTypes) => {
              return (
                <div
                  class={{ [style.menuItemPromoted]: true, [style.promotedItems]: this.promotedItems.length > 1 }}
                  key={'promoted_link_' + item.id}>
                  <MenuLinkPromoted item={item} />
                </div>
              );
            })}
          </li>
        ) : null}
      </ul>
    );
  }

  /**
   * Show the overlay
   */
  private showOverlay() {
    this.$store.commit('ui/setOverlay', true);
    this.masonryLayout();
  }

  /**
   * Hide the overlay
   */
  private hideOverlay() {
    this.$store.commit('ui/setOverlay', false);
  }

  private noOp() {
    // Do nothing
  }

  /**
   * Render sub-elements as a masonry layout
   * TODO: try to find a better solution (this is expensive)
   */
  private masonryLayout() {
    Array.from(this.$el.children).forEach((col) => {
      if (col.children.length > 1) {
        const list = col.children[1];
        const rowGap = parseInt(window.getComputedStyle(list).getPropertyValue('grid-row-gap') || '0', 10) || 1;
        const rowHeight = parseInt(window.getComputedStyle(list).getPropertyValue('grid-auto-rows') || '0', 10) || 0;

        Array.from(list.children).forEach((c) => {
          const height = Array.from(c.children)
            .map((el) => el.getBoundingClientRect().height)
            .reduce((ret, v) => (ret += v), 0);

          (c as HTMLElement).style.gridRowEnd = 'span ' + Math.floor((height + rowGap) / (rowHeight + rowGap) - 1);
        });
      }
    });
  }
}
